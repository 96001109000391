import React, {useState, useEffect, useContext, useRef, PureComponent} from 'react';
import BattleRoundCountdown from './BattleRoundCountdown';
import { GlobalStateContext } from '../providers/GlobalStateProvider';
import {
  useQuery, 
  useLazyQuery,
  gql
} from "@apollo/client";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import Tooltip from "react-simple-tooltip"
import {
  useWindowWidth,
} from '@react-hook/window-size/throttled';
import Select from 'react-select';

import { CEASE_FIRE, COMPUTE_IN_PROGRESS } from '../gameConstants';

const sortOrderStyles = {
  control: (styles) => ({ 
    ...styles, 
    backgroundColor: '#000', 
    color: '#efdbb8',
    outline: 'none',
    borderColor: '#6b6a6b',
    boxShadow: 'none',
    borderRadius: '10px',
    height: '50px',
    "&:hover"  : {
      outline: 'none',
      borderColor: '#444',
      boxShadow: 'none',
    },
  }),
  placeholder: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none',
      "&:hover"  : {
        backgroundColor: '#222',
      },
    };
  },
  singleValue: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  menuList: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
}

const GET_RACCOON_LEADERBOARD_DATA_INTERVAL = gql`
  query GetRaccoonLeaderboardDataInterval($startIndex: Int!, $endIndex: Int!, $tier: Int, $inclusionFields: String) {
    raccoonLeaderboardDataInterval(startIndex: $startIndex, endIndex: $endIndex, tier: $tier, inclusionFields: $inclusionFields) {
      rank
      tierRank
      battle_points
      raccoon_number
      raccoon_metadata_json
      added_time
    }
  }
`;

const GET_RACCOON_LEADERBOARD_RANK = gql`
  query GetRaccoonLeaderboardRank($raccoonIndex: Int!, $tier: Int, $inclusionFields: String) {
    raccoonLeaderboardRank(raccoonIndex: $raccoonIndex, tier: $tier, inclusionFields: $inclusionFields) {
      rank
      tierRank
      tier
    }
  }
`;

const GET_SQUAD_LEADERBOARD_DATA_INTERVAL = gql`
  query GetSquadLeaderboardDataInterval($startIndex: Int!, $endIndex: Int!, $tier: Int) {
    squadLeaderboardDataInterval(startIndex: $startIndex, endIndex: $endIndex, tier: $tier) {
      rank
      battle_points
      epoch_battle_points_added
      officerIndex
      memberRaccoonIndecies
    }
  }
`;

const GET_SQUAD_LEADERBOARD_RANK = gql`
  query GetSquadLeaderboardRank($raccoonIndex: Int!) {
    squadLeaderboardRank(raccoonIndex: $raccoonIndex) {
      rank
    }
  }
`;


const GET_EPOCH_BONUSES = gql`
  query GetEpochBonuses {
    epochBonuses {
      type
      handTrait
      wardrobeTrait
      headTrait
      mouthTrait
      eyewearTrait
      eyeTrait
      partnerProjectPolicyId
      partnerProjectCollection
      partnerProjectName
      syndicateBonusType
      bpmBonus
      epoch
    }
  }
`;


const leaderboardInclusionFields = [
  { value: 'Include All Points (Final Rank)', label: 'Include All Points (Final Rank)' },
  // { value: 'Include Squad Battle Points', label: 'Include Squad Battle Points' },
  // { value: 'Include Holding Points', label: 'Include Holding Points' },
  { value: 'Include Only Battle Points', label: 'Include Only Battle Points' },
];


const epochSnapshots = {
  506: 1725192000,
  507: 1725624000,
  508: 1726056000,
  509: 1726488000,
  510: 1726927076,
  511: 1727352000,
  512: 1727784000,
  513: 1728216000,
  514: 1728648000,
  515: 1729080000
}

const BR5_FIRST_EPOCH_SNAPSHOT_TIME = 1711396800;

let ITEM_COUNT = 10000;
let ITEM_COUNTS = [2000, 3000, 5000];
let SQUAD_COUNT = 2000;

const LOADING = 1;
const LOADED = 2;
let itemStatusMap = {};
let raccoonsMap = {};
let squadStatusMap = {};
let squadsMap = {};
let itemStatusMapTierLeaderboard = {};
let raccoonsMapTierLeaderboard = {};

const isItemLoaded = index => !!itemStatusMap[index];
const isItemLoadedTierLeaderboard = index => !!itemStatusMapTierLeaderboard[index];
const isItemLoadedSquadLeaderboard = index => !!squadStatusMap[index];

const Home = ({isMobile}) => {
  const windowWidth = useWindowWidth();
  
  const [expandedUpcomingBonuses, setExpandedUpcomingBonuses] = useState(false);
  const [expandedStillActiveBonuses, setExpandedStillActiveBonuses] = useState(false);

  const [showLargeNFTs, setShowLargeNFTs] = useState({});

  const [tierLeaderboardTier, setTierLeaderboardTier] = useState(1);

  const [getRaccoonLeaderboardDataInterval, { loading: loadingGetRaccoonLeaderboardDataInterval, error: errorGetRaccoonLeaderboardDataInterval, data: dataGetRaccoonLeaderboardDataInterval, refetch: refetchGetRaccoonLeaderboardDataInterval }] = useLazyQuery(GET_RACCOON_LEADERBOARD_DATA_INTERVAL);
  const [getRaccoonLeaderboardRank, { loading: loadingGetRaccoonLeaderboardRank, error: errorGetRaccoonLeaderboardRank, data: dataGetRaccoonLeaderboarRank, refetch: refetchGetRaccoonLeaderboardRank }] = useLazyQuery(GET_RACCOON_LEADERBOARD_RANK);
  const [getRaccoonLeaderboardDataIntervalTierLeaderboard, { loading: loadingGetRaccoonLeaderboardDataIntervalTierLeaderboard, error: errorGetRaccoonLeaderboardDataIntervalTierLeaderboard, data: dataGetRaccoonLeaderboardDataIntervalTierLeaderboard, refetch: refetchGetRaccoonLeaderboardDataIntervalTierLeaderboard }] = useLazyQuery(GET_RACCOON_LEADERBOARD_DATA_INTERVAL);
  const [getSquadLeaderboardRank, { loading: loadingGetSquadLeaderboardRank, error: errorGetSquadLeaderboardRank, data: dataGetSquadLeaderboarRank, refetch: refetchGetSquadLeaderboardRank }] = useLazyQuery(GET_SQUAD_LEADERBOARD_RANK);
  const [getEpochBonuses, { loading: loadingGetEpochBonuses, error: errorGetEpochBonuses, data: dataGetEpochBonuses, refetch: refetchGetEpochBonuses }] = useLazyQuery(GET_EPOCH_BONUSES);

  const [getSquadLeaderboardDataInterval, { loading: loadingGetSquadLeaderboardDataInterval, error: errorGetSquadLeaderboardDataInterval, data: dataGetSquadLeaderboardDataInterval, refetch: refetchGetSquadLeaderboardDataInterval }] = useLazyQuery(GET_SQUAD_LEADERBOARD_DATA_INTERVAL);

  const infiniteLoaderRef = useRef(null);
  const listRef = useRef(null);
  const squadListRef = useRef(null);
  const [listScrollOffset, setListScrollOffset] = useState(null);
  const [lastIntervalTime, setLastIntervalTime] = useState(Date.now());  
  const [searchRaccoonTextGlobal, setSearchRaccoonTextGlobal] = useState("");
  const [searchRaccoonIndex, setSearchRaccoonIndex] = useState(null);
  const [searchSquadIndex, setSearchSquadIndex] = useState(null);

  const [epochBonuses, setEpochBonuses] = useState(null);

  // TODO
  const infiniteLoaderRefTierLeaderboard = useRef(null);
  const listRefTierLeaderboard = useRef(null);
  const [listScrollOffsetTierLeaderboard, setListScrollOffsetTierLeaderboard] = useState(null);
  const [lastIntervalTimeTierLeaderboard, setLastIntervalTimeTierLeaderboard] = useState(Date.now());  
  const [searchRaccoonTextTierLeaderboard, setSearchRaccoonTextTierLeaderboard] = useState("");
  const [searchRaccoonIndexTierLeaderboard, setSearchRaccoonIndexTierLeaderboard] = useState(null);

  const [searchRaccoonTextSquadLeaderboard, setSearchRaccoonTextSquadLeaderboard] = useState("");

  const [globalLeaderboardInclusionField, setGlobalLeaderboardInclusionField] = useState("Include All Points (Final Rank)");
  const [tierLeaderboardInclusionField, setTierLeaderboardInclusionField] = useState("Include All Points (Final Rank)");

  const { team, regEpochs, teamEpochs } = useContext(GlobalStateContext);

  useEffect(() => {
    getRaccoonLeaderboardDataInterval({variables: {startIndex: 0, endIndex: 10, inclusionFields: globalLeaderboardInclusionField}});
  }, []);

  useEffect(() => {
    getRaccoonLeaderboardDataIntervalTierLeaderboard({variables: {startIndex: 0, endIndex: 10, tier: tierLeaderboardTier, inclusionFields: tierLeaderboardInclusionField}});
  }, []);

  useEffect(() => {
    getEpochBonuses({variables: {}});
  }, []);

  useEffect(() => {
    if (dataGetRaccoonLeaderboardDataInterval && dataGetRaccoonLeaderboardDataInterval.raccoonLeaderboardDataInterval) {
      let newRacs = dataGetRaccoonLeaderboardDataInterval.raccoonLeaderboardDataInterval;
      for (let newRac of newRacs) {
        raccoonsMap[newRac.rank - 1] = newRac;
        itemStatusMap[newRac.rank - 1] = LOADED;
      }
      setLastIntervalTime(Date.now());
    }
  }, [loadingGetRaccoonLeaderboardDataInterval, errorGetRaccoonLeaderboardDataInterval, dataGetRaccoonLeaderboardDataInterval]);

  useEffect(() => {
    if (dataGetRaccoonLeaderboardDataIntervalTierLeaderboard && dataGetRaccoonLeaderboardDataIntervalTierLeaderboard.raccoonLeaderboardDataInterval) {
      let newRacs = dataGetRaccoonLeaderboardDataIntervalTierLeaderboard.raccoonLeaderboardDataInterval;
      for (let newRac of newRacs) {
        raccoonsMapTierLeaderboard[newRac.tierRank - 1] = newRac;
        itemStatusMapTierLeaderboard[newRac.tierRank - 1] = LOADED;
      }
      setLastIntervalTime(Date.now());
    }
  }, [loadingGetRaccoonLeaderboardDataIntervalTierLeaderboard, errorGetRaccoonLeaderboardDataIntervalTierLeaderboard, dataGetRaccoonLeaderboardDataIntervalTierLeaderboard]);


  useEffect(() => {
    console.log('dataGetSquadLeaderboardDataInterval', dataGetSquadLeaderboardDataInterval);
    if (dataGetSquadLeaderboardDataInterval && dataGetSquadLeaderboardDataInterval.squadLeaderboardDataInterval) {
      let newSquads = dataGetSquadLeaderboardDataInterval.squadLeaderboardDataInterval;
      for (let newSquad of newSquads) {
        squadsMap[newSquad.rank - 1] = newSquad;
        squadStatusMap[newSquad.rank - 1] = LOADED;
      }
      setLastIntervalTime(Date.now());
    }
  }, [loadingGetSquadLeaderboardDataInterval, errorGetSquadLeaderboardDataInterval, dataGetSquadLeaderboardDataInterval]);

  useEffect(() => {
    getSquadLeaderboardDataInterval({variables: {startIndex: 0, endIndex: 10}});
  }, []);


  useEffect(() => {
    if (dataGetRaccoonLeaderboarRank && dataGetRaccoonLeaderboarRank.raccoonLeaderboardRank) {
      if (dataGetRaccoonLeaderboarRank.raccoonLeaderboardRank.tier) {
        let racRank = dataGetRaccoonLeaderboarRank.raccoonLeaderboardRank.tierRank;
        setSearchRaccoonIndexTierLeaderboard(racRank);
        if (!isMobile) {
          listRefTierLeaderboard.current.scrollTo(racRank * 110 - 330);
        } else {
          listRefTierLeaderboard.current.scrollTo(racRank * 90 - 330);
        }
        setTimeout(() => {
          getRaccoonLeaderboardDataInterval({variables: {startIndex: racRank-5, endIndex: racRank+5, tier: tierLeaderboardTier, inclusionFields: tierLeaderboardInclusionField}});
        }, 500);
      } else {
        let racRank = dataGetRaccoonLeaderboarRank.raccoonLeaderboardRank.rank;
        setSearchRaccoonIndex(racRank);
        if (!isMobile) {
          listRef.current.scrollTo(racRank * 90 - 330);
        } else {
          listRef.current.scrollTo(racRank * 90 - 330);
        }
        setTimeout(() => {
          getRaccoonLeaderboardDataInterval({variables: {startIndex: racRank-5, endIndex: racRank+5, inclusionFields: globalLeaderboardInclusionField}});
        }, 500);
      }
    }
  }, [dataGetRaccoonLeaderboarRank]);

  useEffect(() => {
    if (dataGetSquadLeaderboarRank && dataGetSquadLeaderboarRank.squadLeaderboardRank) {
      let squadRank = dataGetSquadLeaderboarRank.squadLeaderboardRank.rank;
      setSearchSquadIndex(squadRank);
      if (!isMobile) {
        squadListRef.current.scrollTo(squadRank * 130 - 330);
      } else {
        squadListRef.current.scrollTo(squadRank * 130 - 330);
      }
      setTimeout(() => {
        squadListRef.current.scrollTo(squadRank * 130 - 330);
      }, 1000);
      setTimeout(() => {
        getSquadLeaderboardDataInterval({variables: {startIndex: squadRank-5, endIndex: squadRank+5}});
      }, 500);
    }
  }, [dataGetSquadLeaderboarRank]);

  useEffect(() => {
    if (dataGetEpochBonuses) {
      setEpochBonuses(dataGetEpochBonuses.epochBonuses);
    }
  }, [dataGetEpochBonuses]);

  const loadMoreItems = (startIndex, stopIndex) => {
    getRaccoonLeaderboardDataInterval({variables: {startIndex, endIndex: stopIndex, inclusionFields: globalLeaderboardInclusionField}});
  };

  const loadMoreItemsTierLeaderboard = (startIndex, stopIndex) => {
    getRaccoonLeaderboardDataIntervalTierLeaderboard({variables: {startIndex, endIndex: stopIndex, tier: tierLeaderboardTier, inclusionFields: tierLeaderboardInclusionField}});
  };

  const loadMoreItemsSquadLeaderboard = (startIndex, stopIndex) => {
    getSquadLeaderboardDataInterval({variables: {startIndex, endIndex: stopIndex}});
  };

  const changeTierLeaderboardTier = (newTier) => {
    itemStatusMapTierLeaderboard = {};
    raccoonsMapTierLeaderboard = {};
    setTierLeaderboardTier(newTier);
    setTimeout(() => {
      listRefTierLeaderboard.current.scrollTo(0);
      getRaccoonLeaderboardDataIntervalTierLeaderboard({variables: {startIndex: 0, endIndex: 10, tier: newTier, inclusionFields: tierLeaderboardInclusionField}});
    }, 500);
  }

  let epochHandTraitBonuses = [];
  let epochWardrobeTraitBonuses = [];
  let epochHeadTraitBonuses = [];
  let epochMouthTraitBonuses = [];
  let epochEyewearTraitBonuses = [];
  let epochEyetreatmentTraitBonuses = [];
  let epochPartnerProjectBonuses = {};
  let syndicateBonus = null;

  let handTraitString = ``;
  let wardrobeTraitString = ``;
  let headTraitString = ``;
  let mouthTraitString = ``;
  let eyewearTraitString = ``;
  let eyetreatmentTraitString = ``;

  let currentEpoch = 0;

  if (epochBonuses) {
    epochBonuses.forEach(eb => {
      currentEpoch = eb.epoch;
      if (eb.type === 'handTrait') {
        epochHandTraitBonuses.push(eb);
        if (handTraitString.length < 1) {
          handTraitString = eb.handTrait;
        } else {
          handTraitString = `${handTraitString}, ${eb.handTrait}`
        }
      } else if (eb.type === 'wardrobeTrait') {
        epochWardrobeTraitBonuses.push(eb);
        if (wardrobeTraitString.length < 1) {
          wardrobeTraitString = eb.wardrobeTrait;
        } else {
          wardrobeTraitString = `${wardrobeTraitString}, ${eb.wardrobeTrait}`
        }
      } else if (eb.type === 'headTrait') {
        epochHeadTraitBonuses.push(eb);
        if (headTraitString.length < 1) {
          headTraitString = eb.headTrait;
        } else {
          headTraitString = `${headTraitString}, ${eb.headTrait}`
        }
      } else if (eb.type === 'mouthTrait') {
        epochMouthTraitBonuses.push(eb);
        if (mouthTraitString.length < 1) {
          mouthTraitString = eb.mouthTrait;
        } else {
          mouthTraitString = `${mouthTraitString}, ${eb.mouthTrait}`
        }
      } else if (eb.type === 'eyeTrait') {
        epochEyetreatmentTraitBonuses.push(eb);
        if (eyetreatmentTraitString.length < 1) {
          eyetreatmentTraitString = eb.eyeTrait;
        } else {
          eyetreatmentTraitString = `${eyetreatmentTraitString}, ${eb.eyeTrait}`
        }
      } else if (eb.type === 'eyewearTrait') {
        epochEyewearTraitBonuses.push(eb);
        if (eyewearTraitString.length < 1) {
          eyewearTraitString = eb.eyewearTrait;
        } else {
          eyewearTraitString = `${eyewearTraitString}, ${eb.eyewearTrait}`
        }
      } else if (eb.type === 'partnerProjectPolicyId') {
        if (!epochPartnerProjectBonuses[eb.partnerProjectName]) {
          epochPartnerProjectBonuses[eb.partnerProjectName] = [eb];
        } else {
          epochPartnerProjectBonuses[eb.partnerProjectName].push(eb);
        }
      } else if (eb.type === 'syndicateBonus') {
        syndicateBonus = eb;
      }
    });
  }

  let epochPartnerProjectBonusesArray = [];
  for (var projectName in epochPartnerProjectBonuses) {
    if (epochPartnerProjectBonuses[projectName]) {
      const collectionsInProject = epochPartnerProjectBonuses[projectName];
      epochPartnerProjectBonusesArray.push({
        projectName,
        collectionsInProject
      });
    }
  }

  
  const renderLeaderboardRaccoon = ({rank, tierRank, nft, raccoonNumber, totalPoints, isTierLeaderboard}) => {
    let isHighlighted = rank === searchRaccoonIndex ? true : false;
    if (isTierLeaderboard) {
      isHighlighted = tierRank === searchRaccoonIndexTierLeaderboard ? true : false;
    }
    
    if (typeof tierRank !== 'string') {
      tierRank = `${tierRank}`;
    }

    let tierRankDisplay = "";
    if (tierRank) {
      tierRankDisplay = tierRank.padStart(4, '0');
    }

    return (
      <div key={rank} style={{width: '100%', fontWeight: isHighlighted ? 'bold' : undefined}}> 
        <div className="global_leaderboard_table_row">
          <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "15%", display: 'flex', justifyContent: 'flex-start'}}>
            <div className="global_leaderboard_table_header_cell_text" style={{color: isHighlighted ? '#e08705' : undefined}}>{`${isTierLeaderboard ? tierRankDisplay : rank.toString().padStart(4, '0')}`}</div>
          </div>
          
          <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "15%", display: 'flex', justifyContent: 'flex-start'}}>
            <div className="global_leaderboard_table_header_cell_text" style={{color: isHighlighted ? '#e08705' : undefined}}>{`${isTierLeaderboard ? rank.toString().padStart(4, '0') : tierRankDisplay}`}</div>
            {/*<div className="global_leaderboard_table_header_cell_text" style={{color: isHighlighted ? '#e08705' : undefined}}>{``}</div>*/}
          </div>          
          {/*
          <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "15%", display: 'flex', justifyContent: 'flex-start'}}>
            <div className="global_leaderboard_table_header_cell_text" style={{color: isHighlighted ? '#e08705' : undefined}}>&nbsp;</div>
          </div>          
          */}
          <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "14%", display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px'}}>
            <div className="">
                <a href={`/raccoon?r=${raccoonNumber}`} target="_blank">
                  <img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${raccoonNumber}.png`} className={!isMobile ? "global_leaderboard_nft" : "global_leaderboard_nft_mobile"} 
                    onMouseEnter={() => {
                      let newShowLargeNFTs = showLargeNFTs;
                      newShowLargeNFTs[rank] = true;
                      setShowLargeNFTs({...newShowLargeNFTs});
                    }}
                    onMouseOut={() => {
                      let newShowLargeNFTs = showLargeNFTs;
                      newShowLargeNFTs[rank] = false;
                      setShowLargeNFTs({...newShowLargeNFTs});
                    }}
                    onMouseLeave={() => {
                      let newShowLargeNFTs = showLargeNFTs;
                      newShowLargeNFTs[rank] = false;
                      setShowLargeNFTs({...newShowLargeNFTs});
                    }}
                  />
                </a>
                {showLargeNFTs[rank] && <img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${raccoonNumber}.png`} className={!isMobile ? "global_leaderboard_nft_large" : "global_leaderboard_nft_large_mobile"} style={{width: isMobile ? '240px !important' : '300px !important', maxWidth: isMobile ? '240px !important' : '300px !important', zIndex: '99999 !important'}} />}
            </div>
          </div>                
          <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "21%", display: 'flex', justifyContent: 'center'}}>
            <div className="global_leaderboard_table_header_cell_text" style={{color: isHighlighted ? '#e08705' : '#eedbc0'}}>
              <a href={`/raccoon?r=${raccoonNumber}`} target="_blank" style={{color: isHighlighted ? '#e08705' : '#eedbc0', textDecoration: 'none'}}>Raccoon #{`${raccoonNumber}`.padStart(4, '0')}</a>
            </div>
          </div>                
          <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "35%", display: 'flex', justifyContent: 'flex-end'}}>
            <div className="global_leaderboard_table_header_cell_text" style={{color: isHighlighted ? '#e08705' : undefined}}>{`${totalPoints.toFixed(2)}`}</div>
          </div>                                                
        </div>
        <div className={!isMobile ? "global_leaderboard_table_row_border_wrapper" : "global_leaderboard_table_row_border_wrappe_mobile"}>
        <div className="global_leaderboard_table_row_border" /></div>
      </div>
    );
  }

  const renderLeaderboardLoadingRow = () => {
    return (
      <div style={{width: '100%', height: !isMobile ? '110px' : '90px', minHeight: !isMobile ? '110px' : '90px'}}> 
        <div className="global_leaderboard_table_row_loading">
          <div className="global_leaderboard_table_header_cell" style={{width: "100%", display: 'flex', justifyContent: 'center'}}>
            <div className="global_leaderboard_table_header_cell_text">Loading</div>
          </div>
        </div>
        <div className="global_leaderboard_table_row_border_wrapper"><div className="global_leaderboard_table_row_border" /></div>
      </div>
    );
  }

  class Row extends PureComponent {
    render() {
      const { index, style } = this.props;
      let label;

      if (itemStatusMap[index] === LOADED && raccoonsMap[index]) {
        label = `Row ${index}`;
        let rac = raccoonsMap[index];

        console.log('rr rac', rac);

        return (
          <div className="ListItem" style={style}>
            {renderLeaderboardRaccoon({rank: rac.rank, tierRank: rac.tierRank, nft: {syndicate: 'Farmers', profession: 'Captain'}, raccoonNumber: rac.raccoon_number, totalPoints: rac.battle_points, isTierLeaderboard: false})}
          </div>
        );
      } else {
        return (
          <div className="ListItem" style={style}>
            {renderLeaderboardLoadingRow()}
          </div>
        );
      }
    }
  }

  const renderSquadLeaderboardSquad = ({rank, battle_points, epoch_battle_points_added, officerIndex, memberRaccoonIndecies}) => {
    // let isHighlighted = rank === searchRaccoonIndex ? true : false;
    let isHighlighted = false;

    let isTierLeaderboard = false;

    let noMembers = false;
    if (!memberRaccoonIndecies || memberRaccoonIndecies.length < 1) {
      noMembers = true;
    }
    
    return (
      <div key={rank} style={{width: '100%', fontWeight: isHighlighted ? 'bold' : undefined}}> 
        <div className="global_leaderboard_table_row" style={noMembers ? {paddingBottom: '10px'} : undefined}>
          <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "15%", display: 'flex', justifyContent: 'flex-start'}}>
            <div className="global_leaderboard_table_header_cell_text" style={{color: isHighlighted ? '#e08705' : undefined}}>{`${rank}`.padStart(4, '0')}</div>
          </div>
          <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "15%", display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '0px'}}>
            <div className="">
                <a href={`/raccoon?r=${officerIndex}`} style={{display: 'flex', flexDirection: 'column', color: '#eedbc0', textDecoration: 'none', fontSize: '12px', alignItems: 'center'}} target="_blank">
                  <img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${officerIndex}.png`} className={!isMobile ? "global_leaderboard_nft" : "global_leaderboard_nft_mobile"} style={{marginTop: '10px'}}
                    onMouseEnter={() => {
                      let newShowLargeNFTs = showLargeNFTs;
                      newShowLargeNFTs[rank] = true;
                      setShowLargeNFTs({...newShowLargeNFTs});
                    }}
                    onMouseOut={() => {
                      let newShowLargeNFTs = showLargeNFTs;
                      newShowLargeNFTs[rank] = false;
                      setShowLargeNFTs({...newShowLargeNFTs});
                    }}
                    onMouseLeave={() => {
                      let newShowLargeNFTs = showLargeNFTs;
                      newShowLargeNFTs[rank] = false;
                      setShowLargeNFTs({...newShowLargeNFTs});
                    }}
                  />
                  <div style={{marginTop: '5px', color: parseInt(searchRaccoonTextSquadLeaderboard) === officerIndex ? '#0f0' : '#eedbc0'}}>{officerIndex}</div>
                </a>
                {showLargeNFTs[rank] && <img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${officerIndex}.png`} className={!isMobile ? "global_leaderboard_nft_large" : "global_leaderboard_nft_large_mobile"} style={{width: isMobile ? '240px !important' : '300px !important', maxWidth: isMobile ? '240px !important' : '300px !important', zIndex: '99999 !important'}} />}
            </div>
          </div>                
          <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "40%", display: 'flex', justifyContent: 'flex-start'}}>
            {!noMembers && memberRaccoonIndecies && memberRaccoonIndecies[0] && <a href={`/raccoon?r=${memberRaccoonIndecies[0]}`} style={{display: 'flex', flexDirection: 'column', color: '#eedbc0', textDecoration: 'none', fontSize: '12px', alignItems: 'center'}} target="_blank"><img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${memberRaccoonIndecies[0]}.png`} className={!isMobile ? "global_leaderboard_nft" : "global_leaderboard_nft_mobile"} style={{marginTop: '10px'}}/><div style={{marginTop: '5px', color: parseInt(memberRaccoonIndecies[0]) === parseInt(searchRaccoonTextSquadLeaderboard) ? '#0f0' : undefined}}>{memberRaccoonIndecies[0]}</div></a>}
            {!noMembers && memberRaccoonIndecies && memberRaccoonIndecies[1] && <a href={`/raccoon?r=${memberRaccoonIndecies[1]}`} style={{display: 'flex', flexDirection: 'column', color: '#eedbc0', textDecoration: 'none', fontSize: '12px', alignItems: 'center'}} target="_blank"><img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${memberRaccoonIndecies[1]}.png`} className={!isMobile ? "global_leaderboard_nft" : "global_leaderboard_nft_mobile"} style={{marginTop: '10px'}}/><div style={{marginTop: '5px', color: parseInt(memberRaccoonIndecies[1]) === parseInt(searchRaccoonTextSquadLeaderboard) ? '#0f0' : undefined}}>{memberRaccoonIndecies[1]}</div></a>}
            {!noMembers && memberRaccoonIndecies && memberRaccoonIndecies[2] && <a href={`/raccoon?r=${memberRaccoonIndecies[2]}`} style={{display: 'flex', flexDirection: 'column', color: '#eedbc0', textDecoration: 'none', fontSize: '12px', alignItems: 'center'}} target="_blank"><img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${memberRaccoonIndecies[2]}.png`} className={!isMobile ? "global_leaderboard_nft" : "global_leaderboard_nft_mobile"} style={{marginTop: '10px'}}/><div style={{marginTop: '5px', color: parseInt(memberRaccoonIndecies[2]) === parseInt(searchRaccoonTextSquadLeaderboard) ? '#0f0' : undefined}}>{memberRaccoonIndecies[2]}</div></a>}
            {!noMembers && memberRaccoonIndecies && memberRaccoonIndecies[3] && <a href={`/raccoon?r=${memberRaccoonIndecies[3]}`} style={{display: 'flex', flexDirection: 'column', color: '#eedbc0', textDecoration: 'none', fontSize: '12px', alignItems: 'center'}} target="_blank"><img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${memberRaccoonIndecies[3]}.png`} className={!isMobile ? "global_leaderboard_nft" : "global_leaderboard_nft_mobile"} style={{marginTop: '10px'}}/><div style={{marginTop: '5px', color: parseInt(memberRaccoonIndecies[3]) === parseInt(searchRaccoonTextSquadLeaderboard) ? '#0f0' : undefined}}>{memberRaccoonIndecies[3]}</div></a>}
            {noMembers &&
              <div style={{marginTop: '-20px', backgroundColor: '#000', color: '#000', minHeight: '90px', width: '1px'}}>.</div>
            }
          </div>                
          <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "15%", display: 'flex', justifyContent: 'flex-end'}}>
            <div className="global_leaderboard_table_header_cell_text" style={{color: isHighlighted ? '#e08705' : undefined}}>{`${epoch_battle_points_added ? epoch_battle_points_added.toFixed(2) : ''}`}</div>
          </div>                                                
          <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "15%", display: 'flex', justifyContent: 'flex-end'}}>
            <div className="global_leaderboard_table_header_cell_text" style={{color: isHighlighted ? '#e08705' : undefined}}>{`${battle_points ? battle_points.toFixed(2) : ''}`}</div>
          </div>                                                
        </div>
        <div className={!isMobile ? "global_leaderboard_table_row_border_wrapper" : "global_leaderboard_table_row_border_wrappe_mobile"}>
        <div className="global_leaderboard_table_row_border" /></div>
      </div>
    );
  }

  class SquadLeaderboardRow extends PureComponent {
    render() {
      const { index, style } = this.props;
      let label;

      if (squadStatusMap[index] === LOADED && squadsMap[index]) {
        label = `Row ${index}`;
        let squad = squadsMap[index];
        return (
          <div className="ListItem" style={style}>
            {renderSquadLeaderboardSquad({rank: squad.rank, battle_points: squad.battle_points, epoch_battle_points_added: squad.epoch_battle_points_added, officerIndex: squad.officerIndex, memberRaccoonIndecies: squad.memberRaccoonIndecies})}
          </div>
        );
      } else {
        return (
          <div className="ListItem" style={style}>
            {renderLeaderboardLoadingRow()}
          </div>
        );
      }
    }
  }

  class RowTierLeaderboard extends PureComponent {
    render() {
      const { index, style } = this.props;
      let label;

      if (itemStatusMapTierLeaderboard[index] === LOADED && raccoonsMapTierLeaderboard[index]) {
        label = `Row ${index}`;
        let rac = raccoonsMapTierLeaderboard[index];
        return (
          <div className="ListItem" style={style}>
            {renderLeaderboardRaccoon({rank: rac.rank, tierRank: rac.tierRank, nft: {syndicate: 'Farmers', profession: 'Captain'}, raccoonNumber: rac.raccoon_number, totalPoints: rac.battle_points, isTierLeaderboard: true})}
          </div>
        );
      } else {
        return (
          <div className="ListItem" style={style}>
            {renderLeaderboardLoadingRow()}
          </div>
        );
      }
    }
  }


  return (
    <>
      <div className="home_wrapper">
        <div className="white">
          <div className="logo_container">
            <img src="rs_logo_banner.png" className={isMobile ? "logo_banner_mobile" : "logo_banner"} /> <br/>
          </div>
            {!isMobile && <img src="home_hero.png" className="hero_image" />}
            {isMobile && <img src="home_hero_mobile.png" className="hero_image_mobile" />}
            <br/>
          <br/>
          <div style={{display: 'flex', flexDirection: isMobile || windowWidth < 1350 ? 'column' : 'row', alignItems: 'center', justifyContent: 'center'}}>
            <div className={!isMobile && windowWidth >= 1350 ? "battle_round_dates_row battle_round_dates_row_l" : "battle_round_dates_row battle_round_dates_row_l_mobile"}>
            
            {/*<div className="home_battle_round_text" style={{marginTop: '30px'}}>BATTLE ROUND 6 FINAL RESULTS BELOW!</div>*/}
            
            {/*
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
              <div className="home_battle_round_text" style={{marginTop: '30px'}}>BATTLE ROUND 6 SQUAD SNAPSHOT IN</div>
              <div><BattleRoundCountdown unixtime={BR5_FIRST_EPOCH_SNAPSHOT_TIME} /></div>
            </div>
            */}
            
            {/*
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
              <div className="home_battle_round_text" style={{marginTop: '30px'}}>COMPUTING PRE-BATTLE ROUND SNAPSHOT</div>
            </div>
            */}


              {COMPUTE_IN_PROGRESS && <span className="home_battle_round_text">COMPUTING BONUSES NOW</span>}

              {!COMPUTE_IN_PROGRESS  && !CEASE_FIRE && (
                <>
                  <span className="home_battle_round_text">BATTLE ROUND 7 ENDS:</span>
                  <BattleRoundCountdown unixtime={epochSnapshots[515]} />
                </>
              )}
            </div>
            {<div className={!isMobile && windowWidth >= 1350 ? "battle_round_dates_row battle_round_dates_row_r" : "battle_round_dates_row battle_round_dates_row_r_mobile"}>
              {!COMPUTE_IN_PROGRESS && !CEASE_FIRE && (
                <>
                  <span className="home_battle_round_text">EPOCH {currentEpoch} SNAPSHOT:</span>
                  {epochSnapshots[currentEpoch] && <BattleRoundCountdown unixtime={epochSnapshots[currentEpoch]} />}
                </>
              )}
            </div> 
            }   
          </div>     
          <br/>

          {!COMPUTE_IN_PROGRESS && !CEASE_FIRE && <div className="upcoming_bonuses_box">
            <div className={!isMobile ? "upcoming_bonuses_text" : "upcoming_bonuses_text_mobile"} onClick={() => {
                setExpandedUpcomingBonuses(!expandedUpcomingBonuses);
              }}>
              {expandedUpcomingBonuses && <span className="upcoming_bonuses_minus_sign" ><img src="collapse_icon.png" className="collapse_icon" /></span>}
              {!expandedUpcomingBonuses && <span className="upcoming_bonuses_plus_sign" ><img src="expand_icon.png" className="expand_icon" /></span>}
              EPOCH {currentEpoch} BONUSES
            </div>
            {expandedUpcomingBonuses && (
              <div>
                <div className="upcoming_bonuses_inner_box">
                  <div className="upcoming_bonus_box">
                    <div className="upcoming_bonus_title">HAND TRAITS - 20% BONUS</div>
                    <div className="upcoming_bonus_detail">
                      <div style={{marginTop: '5px'}}>For Epoch {currentEpoch}, Raccoons holding the following hand traits will receive a 20% bonus:</div>
                      <div key={`htb`} style={{marginTop: '10px'}}>{handTraitString}</div>
                    </div>
                  </div>
                  <div className="upcoming_bonus_box">
                    <div className="upcoming_bonus_title">WARDROBE TRAITS - 15% BONUS</div>
                    <div className="upcoming_bonus_detail">
                      <div style={{marginTop: '5px'}}>For Epoch {currentEpoch}, Raccoons wearing the following wardrobe traits will receive a 15% bonus:</div>
                      <div key={`htb`} style={{marginTop: '10px'}}>{wardrobeTraitString}</div>
                    </div>
                  </div>     
                  <div className="upcoming_bonus_box">
                    <div className="upcoming_bonus_title">HEAD TRAITS - 10% BONUS</div>
                    <div className="upcoming_bonus_detail">
                      <div style={{marginTop: '5px'}}>For Epoch {currentEpoch}, Raccoons with the following head traits will receive a 10% bonus:</div>
                      <div key={`htb`} style={{marginTop: '10px'}}>{headTraitString}</div>
                    </div>
                  </div>           
                  <div className="upcoming_bonus_box">
                    <div className="upcoming_bonus_title">MOUTH TRAITS - 10% BONUS</div>
                    <div className="upcoming_bonus_detail">
                      <div style={{marginTop: '5px'}}>For Epoch {currentEpoch}, Raccoons with the following mouth traits will receive a 10% bonus:</div>
                      <div key={`htb`} style={{marginTop: '10px'}}>{mouthTraitString}</div>
                    </div>
                  </div>  
                  <div className="upcoming_bonus_box">
                    <div className="upcoming_bonus_title">EYEWEAR TRAITS - 10% BONUS</div>
                    <div className="upcoming_bonus_detail">
                      <div style={{marginTop: '5px'}}>For Epoch {currentEpoch}, Raccoons with the following eyewear traits will receive a 10% bonus:</div>
                      <div key={`htb`} style={{marginTop: '10px'}}>{eyewearTraitString}</div>
                    </div>
                  </div>       
                  <div className="upcoming_bonus_box">
                    <div className="upcoming_bonus_title">EYE TREATMENT TRAITS - 10% BONUS</div>
                    <div className="upcoming_bonus_detail">
                      <div style={{marginTop: '5px'}}>For Epoch {currentEpoch}, Raccoons with the following eye treatment traits will receive a 10% bonus:</div>
                      <div key={`htb`} style={{marginTop: '10px'}}>{eyetreatmentTraitString}</div>
                    </div>
                  </div>                                                                        
                  {/*
                  <div className="upcoming_bonus_box">
                    <div className="upcoming_bonus_title">PARTNER PROJECT - 5% BONUS</div>
                    <div className="upcoming_bonus_detail">
                      <div style={{marginTop: '5px'}}>For Epoch {currentEpoch}, Raccoons in a wallet with any one of the following Partner Project NFTs will receive a 5% bonus (you just need 1 NFT, from just 1 of these projects):</div>
                      {epochPartnerProjectBonusesArray.map((pp, i) => {

                        return (
                          <div key={`pp_${i}`} style={{marginTop: '10px'}}>
                            {pp.projectName}
                            <ul style={{marginTop: '3px'}}>
                              {pp.collectionsInProject.map((collection, j) => {
                                return <li key={`pp_col_${i}_${j}`}>{collection.partnerProjectCollection} - policy id {collection.partnerProjectPolicyId}</li>
                              })}
                            </ul>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  */}
                </div>
                <div className="upcoming_bonus_box">
                  <div className="upcoming_bonus_title">SYNDICATE BONUS</div>
                  <div className="upcoming_bonus_detail">For Epoch {currentEpoch}, if you have a {syndicateBonus.syndicateBonusType} Raccoon in your wallet, then that Raccoon and all other Raccoons in your wallet earn 5 Battle Points!</div>
                </div>
              </div>
            )}
          </div>
          }



          {/*
          <div className="upcoming_bonuses_box" style={{marginTop: '40px'}}>
            <div className={!isMobile ? "upcoming_bonuses_text" : "upcoming_bonuses_text_mobile"} onClick={() => {
                setExpandedStillActiveBonuses(!expandedStillActiveBonuses);
              }}>
              {expandedStillActiveBonuses && <span className="upcoming_bonuses_minus_sign" ><img src="collapse_icon.png" className="collapse_icon" /></span>}
              {!expandedStillActiveBonuses && <span className="upcoming_bonuses_plus_sign" ><img src="expand_icon.png" className="expand_icon" /></span>}
              STILL ACTIVE BONUSES
            </div>
            {expandedStillActiveBonuses && 
            <div className="upcoming_bonuses_inner_box">
              <div className="upcoming_bonus_box">
                <div className="upcoming_bonus_title">HAND TRAITS - 10% BONUS</div>
                <div className="upcoming_bonus_detail">
                  <div style={{marginTop: '5px'}}>For Epoch 404, Raccoons holding the following hand traits will receive a 10% bonus:</div>
                  <div style={{marginTop: '10px'}}>Telephone, Ukulele, War Horn, White Rat, Straight Razor, Round Rum Bottle, Candle Holder, Pumpkin, Watermelon, Nunchucks, Lightbulb</div>
                </div>
              </div>
              <div className="upcoming_bonus_box">
                <div className="upcoming_bonus_title">HAND TRAITS - 15% BONUS</div>
                <div className="upcoming_bonus_detail">
                  <div style={{marginTop: '5px'}}>For Epoch 404, Raccoons holding the following hand traits will receive a 15% bonus:</div>
                  <div style={{marginTop: '10px'}}>Apple, Alarm Clock Bomb, Binoculars, Trashbag, Pink Flask, Money Bag Blue, Coins, Toilet Paper Roll</div>
                </div>
              </div>
            </div>
            }
          </div>
          */}

        <center>
          <div className="global_leaderboard_header_box" style={{paddingLeft: '0px'}}>
            <div className={!isMobile ? "leaderboard_title_row" : "leaderboard_title_row_mobile"}>
              <div className={!isMobile ? "global_leaderboard_title_text" : "global_leaderboard_title_text_mobile"}>
                  GLOBAL LEADERBOARD
              </div>      
              <div className="global_leaderboard_search_box_wrapper">
                <div style={{marginRight: '30px', marginTop: '10px'}}>
                  <Select 
                    options={leaderboardInclusionFields} 
                    defaultValue={{value: globalLeaderboardInclusionField, label: globalLeaderboardInclusionField}}
                    styles={sortOrderStyles}
                    onChange={(option) => {
                      if (option && option.value) {
                        setGlobalLeaderboardInclusionField(option.value);
                        getRaccoonLeaderboardDataInterval({variables: {startIndex: 0, endIndex: 10, inclusionFields: option.value}});
                      }
                    }}
                  />
                </div>

                <div className="global_leaderboard_search_box_component">
                  <input 
                    type="text" 
                    className="global_leaderboard_search_box_text_input" 
                    placeholder="Search Raccoon #"
                    value={searchRaccoonTextGlobal} 
                    onChange={e => {
                      let inputString = e.target.value;
                      inputString = inputString.replace(/\D/g,'');
                      if (inputString.length > 4 && inputString !== '10000') {
                        inputString = inputString.substring(0, 4);
                      }
                      setSearchRaccoonTextGlobal(inputString);
                      if (inputString && inputString.length > 0) {
                        getRaccoonLeaderboardRank({variables: {raccoonIndex: parseInt(inputString), inclusionFields: globalLeaderboardInclusionField}});
                      }
                    }}
                  />
                  <img className="global_leaderboard_search_box_icon" src="mag-glass.png" />
                </div>
              </div>    
            </div>
          </div>

          <div className={!isMobile ? "global_leaderboard_box" : "global_leaderboard_box_mobile"}>
            <img src="ScrollUpArrow.png" className="scroll_up_arrow_global_leaderboard"  onClick={() => {
              let newOffset = listScrollOffset - 550;
              if (newOffset < 0) {
                newOffset = 0;
              }
              if (newOffset < listScrollOffset) {
                listRef.current.scrollTo(newOffset);
                let racRank = Math.floor(newOffset / 110);
                if (isMobile) {
                  racRank = Math.floor(newOffset / 90);
                }
                setTimeout(() => {
                  getRaccoonLeaderboardDataInterval({variables: {startIndex: racRank-8, endIndex: racRank+2, inclusionFields: globalLeaderboardInclusionField}});
                }, 500);
              }
            }} />
            <div className="global_leaderboard_table">
              <div className="global_leaderboard_table_header">
                <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "15%", display: 'flex', justifyContent: 'flex-start'}}>
                  RANK
                </div>
                <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "15%", display: 'flex', justifyContent: 'flex-start'}}>
                  TIER RANK
                </div>                
                <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "14%", display: 'flex', justifyContent: 'center'}}>
                  NFT
                </div>                
                <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "21%", display: 'flex', justifyContent: 'center'}}>
                  RACCOON #
                </div>                
                <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "35%", display: 'flex', justifyContent: 'flex-end'}}>
                  TOTAL POINTS
                </div>                                                
              </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
              <div style={{display: 'flex', width: '100%', height: '500px', padding: '0px 10px'}}>
                <AutoSizer>
                  {({height, width}) => {
                    return (  
                      <InfiniteLoader
                        isItemLoaded={isItemLoaded}
                        itemCount={ITEM_COUNT}
                        loadMoreItems={loadMoreItems}
                        ref={infiniteLoaderRef}
                      >
                        {({ onItemsRendered, ref }) => (
                          <List
                            className="List"
                            height={500}
                            itemCount={ITEM_COUNT}
                            itemSize={90}
                            onItemsRendered={onItemsRendered}
                            ref={(elem) => { 
                              ref(elem);
                              listRef.current = elem;
                            }}
                            width={width}
                            onScroll={(event) => {
                              setListScrollOffset(event.scrollOffset);
                              setShowLargeNFTs({});
                            }}
                          >
                            {Row}
                          </List>
                        )}
                      </InfiniteLoader>
                    );
                  }}
                </AutoSizer>
              </div>
            </div>
            <img src="ScrollDownArrow.png" className="scroll_down_arrow_global_leaderboard" onClick={() => {
              let newOffset = listScrollOffset + 550;
              if (!isMobile) {
                if (newOffset > ((ITEM_COUNT * 110) - 440)) {
                  newOffset = ITEM_COUNT * 110 - 440;
                }
              } else {
                if (newOffset > ((ITEM_COUNT * 90) - 440)) {
                  newOffset = ITEM_COUNT * 90 - 440;
                }
              }
              if (newOffset > 899700) {
                newOffset = 899700;
              }
              
              if (newOffset > listScrollOffset) {
                listRef.current.scrollTo(newOffset);
                let racRank = Math.floor(newOffset / 110);
                if (isMobile) {
                  racRank = Math.floor(newOffset / 90);
                }
                setTimeout(() => {
                  // getRaccoonLeaderboardDataInterval({variables: {startIndex: racRank-2, endIndex: racRank+8}});
                }, 500);
              }
            }} />
          </div>
        </center>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      
      
        <div className="global_leaderboard_header_box" style={{paddingLeft: '0px'}}>
          <div className={!isMobile ? "leaderboard_title_row" : "leaderboard_title_row_mobile"}>
            <div className={!isMobile ? "global_leaderboard_title_text" : "global_leaderboard_title_text_mobile"}>
              <div className={tierLeaderboardTier === 1 ? "tier_leaderboard_text_selected" : "tier_leaderboard_text_unselected"} style={{fontSize: isMobile ? '30px' : undefined}} onClick={() => changeTierLeaderboardTier(1)}>TIER 1</div>
                <div className="tier_leaderboard_text_separator">|</div>
                <div className={tierLeaderboardTier === 2 ? "tier_leaderboard_text_selected" : "tier_leaderboard_text_unselected"} style={{fontSize: isMobile ? '30px' : undefined}} onClick={() => changeTierLeaderboardTier(2)}>TIER 2</div> 
                <div className="tier_leaderboard_text_separator">|</div>
                <div className={tierLeaderboardTier === 3 ? "tier_leaderboard_text_selected" : "tier_leaderboard_text_unselected"} style={{fontSize: isMobile ? '30px' : undefined}} onClick={() => changeTierLeaderboardTier(3)}>TIER 3</div>
            </div>      
            <div className="global_leaderboard_search_box_wrapper">
              <div style={{marginRight: '30px', marginTop: '10px'}}>
                    <Select 
                      options={leaderboardInclusionFields} 
                      defaultValue={{value: tierLeaderboardInclusionField, label: tierLeaderboardInclusionField}}
                      styles={sortOrderStyles}
                      onChange={(option) => {
                        if (option && option.value) {
                          setTierLeaderboardInclusionField(option.value);
                          getRaccoonLeaderboardDataIntervalTierLeaderboard({variables: {startIndex: 0, endIndex: 10, tier: tierLeaderboardTier, inclusionFields: option.value}});
                        }
                      }}
                    />
              </div>
              <div className="global_leaderboard_search_box_component">
                <input 
                  type="text" 
                  className="global_leaderboard_search_box_text_input" 
                  placeholder="Search Raccoon #"
                  value={searchRaccoonTextTierLeaderboard} 
                  onChange={e => {
                    let inputString = e.target.value;
                    inputString = inputString.replace(/\D/g,'');
                    if (inputString.length > 4 && inputString !== '10000') inputString = inputString.substring(0, 4);
                    setSearchRaccoonTextTierLeaderboard(inputString);
                    getRaccoonLeaderboardRank({variables: {raccoonIndex: parseInt(inputString), tier: tierLeaderboardTier, inclusionFields: tierLeaderboardInclusionField}});
                  }}
                />
                <img className="global_leaderboard_search_box_icon" src="mag-glass.png" />
              </div>
            </div>    
          </div>
        </div>
        

{/*
        <div className={!isMobile ? "global_leaderboard_box" : "global_leaderboard_box_mobile"}>
          <div style={{width: '100%', color: '#efddb7', fontSize: '20px', textAlign: 'center', padding: '20px 0px', marginBottom: isMobile ? '30px' : undefined}}>
            Tier leaderboard will bee available at the start of epoch 507
          </div>          
        </div> 
*/}




        <div className={!isMobile ? "global_leaderboard_box" : "global_leaderboard_box_mobile"}>
          <img src="ScrollUpArrow.png"className="scroll_up_arrow_global_leaderboard"  onClick={() => {
            let newOffset = listScrollOffsetTierLeaderboard - 550;
            if (newOffset < 0) {
              newOffset = 0;
            }
            if (newOffset < listScrollOffsetTierLeaderboard) {
              listRefTierLeaderboard.current.scrollTo(newOffset);
              let racRank = Math.floor(newOffset / 110);
              if (isMobile) {
                racRank = Math.floor(newOffset / 90);
              }
              setTimeout(() => {
                getRaccoonLeaderboardDataIntervalTierLeaderboard({variables: {startIndex: racRank-8, endIndex: racRank+2, tier: tierLeaderboardTier, inclusionFields: tierLeaderboardInclusionField}});
              }, 500);
            }
          }} />
          <div className="global_leaderboard_table">
          </div>
          <div className="global_leaderboard_table">
            <div className="global_leaderboard_table_header" style={{padding: '0px 0px'}}>
              <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "15%", display: 'flex', justifyContent: 'flex-start'}}>
                RANK
              </div>
              <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "15%", display: 'flex', justifyContent: 'flex-start'}}>
                GLOBAL RANK
              </div>              
              <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "14%", display: 'flex', justifyContent: 'center'}}>
                NFT
              </div>                
              <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "21%", display: 'flex', justifyContent: 'center'}}>
                RACCOON #
              </div>                
              <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "35%", display: 'flex', justifyContent: 'flex-end'}}>
                TOTAL POINTS
              </div>                                                
            </div>
          </div>
          <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
            <div style={{display: 'flex', width: '100%', height: '500px'}}>
              <AutoSizer>
                {({height, width}) => {
                  return (  
                    <InfiniteLoader
                      isItemLoaded={isItemLoadedTierLeaderboard}
                      itemCount={ITEM_COUNTS[tierLeaderboardTier - 1]}
                      loadMoreItems={loadMoreItemsTierLeaderboard}
                      ref={infiniteLoaderRefTierLeaderboard}
                    >
                      {({ onItemsRendered, ref }) => (
                        <List
                          className="List"
                          height={500}
                          itemCount={ITEM_COUNTS[tierLeaderboardTier - 1]}
                          itemSize={!isMobile ? 110 : 90}
                          onItemsRendered={onItemsRendered}
                          ref={(elem) => { 
                            ref(elem);
                            listRefTierLeaderboard.current = elem;
                          }}
                          width={width}
                          onScroll={(event) => {
                            setListScrollOffsetTierLeaderboard(event.scrollOffset);
                          }}
                        >
                          {RowTierLeaderboard}
                        </List>
                      )}
                    </InfiniteLoader>
                  );
                }}
              </AutoSizer>
            </div>
          </div>
          <img src="ScrollDownArrow.png" className="scroll_down_arrow_global_leaderboard" onClick={() => {
            let newOffset = listScrollOffsetTierLeaderboard + 550;
            if (!isMobile) {
              if (newOffset > ((ITEM_COUNTS[tierLeaderboardTier] * 110) - 440)) {
                newOffset = ITEM_COUNTS[tierLeaderboardTier] * 110 - 440;
              }
            } else {
              if (newOffset > ((ITEM_COUNTS[tierLeaderboardTier] * 90) - 440)) {
                newOffset = ITEM_COUNTS[tierLeaderboardTier] * 90 - 440;
              }
            }
            
            if (newOffset > listScrollOffsetTierLeaderboard) {
              listRefTierLeaderboard.current.scrollTo(newOffset);
              let racRank = Math.floor(newOffset / 110);
              if (isMobile) {
                racRank = Math.floor(newOffset / 90);
              }
              setTimeout(() => {
                getRaccoonLeaderboardDataIntervalTierLeaderboard({variables: {startIndex: racRank-2, endIndex: racRank+8, tier: tierLeaderboardTier, inclusionFields: tierLeaderboardInclusionField}});
              }, 500);
            }
          }} />
        </div> 
        


        <div className="global_leaderboard_header_box" style={{paddingLeft: '0px', marginTop: '200px'}}>
          <div className={!isMobile ? "leaderboard_title_row" : "leaderboard_title_row_mobile"}>
            <div className={!isMobile ? "global_leaderboard_title_text" : "global_leaderboard_title_text_mobile"}>
              <div className={"tier_leaderboard_text_selected"} style={{fontSize: isMobile ? '30px' : undefined}}>SQUAD LEADERBOARD</div>
            </div>      
            <div className="global_leaderboard_search_box_wrapper">
              <div className="global_leaderboard_search_box_component">
                <input 
                  type="text" 
                  className="global_leaderboard_search_box_text_input" 
                  placeholder="Search Raccoon #"
                  value={searchRaccoonTextSquadLeaderboard} 
                  onChange={e => {
                    let inputString = e.target.value;
                    inputString = inputString.replace(/\D/g,'');
                    if (inputString.length > 4 && inputString !== '10000') inputString = inputString.substring(0, 4);
                    setSearchRaccoonTextSquadLeaderboard(inputString);
                    getSquadLeaderboardRank({variables: {raccoonIndex: parseInt(inputString)}});
                  }}
                />
                <img className="global_leaderboard_search_box_icon" src="mag-glass.png" />
              </div>
            </div>    
          </div>
        </div>


{/*
        <div className={!isMobile ? "global_leaderboard_box" : "global_leaderboard_box_mobile"} style={{color: '#efdbb8'}}>
                  <div style={{padding: '20px 0px', fontSize: '20px'}}>Squad leaderboard will bee available at the start of epoch 507</div>
        </div>
*/}




        <div className={!isMobile ? "global_leaderboard_box" : "global_leaderboard_box_mobile"}>
            <img src="ScrollUpArrow.png" className="scroll_up_arrow_global_leaderboard"  onClick={() => {
              let newOffset = listScrollOffset - 550;
              if (newOffset < 0) {
                newOffset = 0;
              }
              if (newOffset < listScrollOffset) {
                squadListRef.current.scrollTo(newOffset);
                let racRank = Math.floor(newOffset / 110);
                if (isMobile) {
                  racRank = Math.floor(newOffset / 90);
                }
                setTimeout(() => {
                  getRaccoonLeaderboardDataInterval({variables: {startIndex: racRank-8, endIndex: racRank+2, inclusionFields: globalLeaderboardInclusionField}});
                }, 500);
              }
            }} />
            <div className="global_leaderboard_table">
              <div className="global_leaderboard_table_header">
                <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "15%", display: 'flex', justifyContent: 'flex-start'}}>
                  RANK
                </div>
                <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "15%", display: 'flex', justifyContent: 'flex-start'}}>
                  OFFICER
                </div>                
                <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "40%", display: 'flex', justifyContent: 'flex-start'}}>
                  MEMBERS
                </div>                
                <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "15%", display: 'flex', justifyContent: 'flex-end'}}>
                  POINTS LAST EPOCH
                </div>                   
                <div className={!isMobile ? "global_leaderboard_table_header_cell" : "global_leaderboard_table_header_cell_mobile"} style={{width: "15%", display: 'flex', justifyContent: 'flex-end'}}>
                  TOTAL POINTS
                </div>                                                
              </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
              <div style={{display: 'flex', width: '100%', height: '500px', padding: '0px 10px'}}>
                <AutoSizer>
                  {({height, width}) => {
                    return (  
                      <InfiniteLoader
                        isItemLoaded={isItemLoadedSquadLeaderboard}
                        itemCount={SQUAD_COUNT}
                        loadMoreItems={loadMoreItemsSquadLeaderboard}
                        ref={infiniteLoaderRef}
                      >
                        {({ onItemsRendered, ref }) => (
                          <List
                            className="List"
                            height={500}
                            itemCount={SQUAD_COUNT}
                            itemSize={130}
                            onItemsRendered={onItemsRendered}
                            ref={(elem) => { 
                              ref(elem);
                              squadListRef.current = elem;
                            }}
                            width={width}
                            onScroll={(event) => {
                              setListScrollOffset(event.scrollOffset);
                              setShowLargeNFTs({});
                            }}
                          >
                            {SquadLeaderboardRow}
                          </List>
                        )}
                      </InfiniteLoader>
                    );
                  }}
                </AutoSizer>
              </div>
            </div>
            <img src="ScrollDownArrow.png" className="scroll_down_arrow_global_leaderboard" onClick={() => {
              let newOffset = listScrollOffset + 550;
              if (!isMobile) {
                if (newOffset > ((ITEM_COUNT * 110) - 440)) {
                  newOffset = ITEM_COUNT * 110 - 440;
                }
              } else {
                if (newOffset > ((ITEM_COUNT * 90) - 440)) {
                  newOffset = ITEM_COUNT * 90 - 440;
                }
              }
              if (newOffset > 899700) {
                newOffset = 899700;
              }
              
              if (newOffset > listScrollOffset) {
                squadListRef.current.scrollTo(newOffset);
                let racRank = Math.floor(newOffset / 110);
                if (isMobile) {
                  racRank = Math.floor(newOffset / 90);
                }
                setTimeout(() => {
                  // getRaccoonLeaderboardDataInterval({variables: {startIndex: racRank-2, endIndex: racRank+8, inclusionFields: globalLeaderboardInclusionField}});
                }, 500);
              }
            }} />
          </div>



        </div>
      </div>
    </>
  );
};

export default Home;

